<template>
    <!-- 考生信息 -->
    <div class="examinee_box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item style="margin-bottom: 0px;">
                <span class="tips"><i class="iconfont">&#xe67b;</i>注：高考省份、高考年份、选科保存后不允许修改，预估分数在模拟填报期间可任意填写</span>
            </el-form-item>
            <el-form-item label="高考省份" prop="ExamProvinceId">
                <el-select v-model="ruleForm.ExamProvinceId" placeholder="请选择" clearable style="width:100%"
                    @change="handleChangeArea" :disabled="isAdd">
                    <el-option v-for="item in ExamProvinceData" :key="item.ProvinceId" :label="item.ProvinceName"
                        :value="item.ProvinceId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="高考年份" prop="ExamYear">
                <el-date-picker v-model="ruleForm.ExamYear" type="year" placeholder="选择年" value-format="yyyy" clearable
                    style="width:100%" @change="handleChangeYear" :disabled="isAdd"></el-date-picker>
            </el-form-item>
            <el-form-item label="选科">
                <span class="tips" v-if="isCheack == false && isAdd == false"><i
                        class="iconfont">&#xe67b;</i>请先选择“高考省份”和“高考年份”</span>
                <div v-else>

                    <div class="subject" v-for="(item, index) in ExamData.RuleList" :key="index">
                        <span>{{ item.SelectExamTitle }}</span>
                        <div class="subject-item">
                            <el-checkbox-group v-model="item.selected" :max="item.MaxSelectCount"
                                :disabled="subjectDisabled">
                                <el-checkbox v-for="subItem in item.SelectExamSubjects" :key="subItem"
                                    :label="subItem"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="预估分数" prop="Score">
                <el-input v-model="ruleForm.Score" maxlength="3"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import API from "@/api/config";
import { getStore } from "@/utils/utils";
import { Message } from 'element-ui';
import { setStore } from "../../../utils/utils";
export default {
    data() {
        return {
            ruleForm: {
                ExamProvinceId: '',
                ExamYear: '',
                SubjectType: '',
                SelectSubjectList: [
                    {
                        SelectExamSubjects: []
                    },
                    {
                        SelectExamSubjects: []
                    }
                ],
                Score: '',
            },
            ExamProvinceData: [],
            ExamData: {},
            isCheack: false,
            isAdd: true,
            rules: {
                ExamProvinceId: [
                    { required: true, message: '请选择高考省份', trigger: 'change' }
                ],
                ExamYear: [
                    { required: true, message: '请选择高考年份', trigger: 'change' }
                ],
                Score: [
                    { required: true, message: '请输入预估分数', trigger: 'blur' }
                ],
            },

            subjectDisabled: false
        }
    },
    mounted() {
        let area = getStore('ProvinceList');
        if (area != null && area != undefined && area != '') {
            this.ExamProvinceData = JSON.parse(area);
        }
        this.GetUserStudent();// 获取学生高考信息

    },
    methods: {
        // 获取学生基本信息
        GetUserStudent() {
            API.Personal.GetUserStudent().then(res => {
                if (res.Code === 0) {
                    if (res.Data != null) {
                        if (res.Data.Exam != null) {
                            this.ruleForm = res.Data.Exam;
                            this.ruleForm.ExamYear = res.Data.Exam.ExamYear + '';

                            this.$store.dispatch('user/judgeNewExam', {
                                id: res.Data.Exam.ExamProvinceId,
                                year: res.Data.Exam.ExamYear,
                            }).then(examInfo => {
                                this.subjectDisabled = false
                                if (examInfo.IsNewExam) {
                                    if (res.Data.Exam.SelectSubjectList.length > 0) {
                                        this.subjectDisabled = true
                                    }
                                } else {
                                    if (res.Data.Exam.SubjectType) {
                                        this.subjectDisabled = true
                                    }
                                }


                                this.handleSubject(examInfo, res.Data.Exam)
                            })



                            this.isAdd = true;
                        } else {
                            this.isAdd = false;

                            this.$store.dispatch('tool/getSystemYear').then(year => {
                                this.ruleForm.ExamYear = String(year)
                            })
                            // API.Wish.WishYear().then(res => {
                            //     this.ruleForm.ExamYear = String(res.data.Year)
                            // })
                        }
                    }
                    else {
                        this.isAdd = false;
                    }
                }
            });
        },
        // 高考省份切换
        handleChangeArea(value) {
            if (value != '' && (this.ruleForm.ExamYear != '' && this.ruleForm.ExamYear != null)) {
                // 判断新老高考
                this.$store.dispatch("user/judgeNewExam", {
                    id: value,
                    year: this.ruleForm.ExamYear,
                }).then(res => {
                    this.handleSubject(res)
                });

                this.isCheack = true;
            }
            else {
                this.isCheack = false;
            }
        },
        // 高考年份切换
        handleChangeYear(value) {
            if (this.ruleForm.ExamProvinceId != '' && value != null) {
                // 判断新老高考
                this.$store.dispatch("user/judgeNewExam", {
                    id: this.ruleForm.ExamProvinceId,
                    year: value,
                }).then(res => {
                    this.handleSubject(res)
                });

                this.isCheack = true;
            }
            else {
                this.isCheack = false;
            }
        },

        handleSubject(res, exam) {
            exam = exam || {}
            if (res.IsNewExam) {
                if (!this.ExamData.IsNewExam || !this.ExamData.RuleList || this.ExamData.RuleList.length !== res.RuleList.length) { // refresh

                    res.RuleList = res.RuleList.map((item, index) => {
                        item.selected = (exam.SelectSubjectList && exam.SelectSubjectList.length !== 0) ? exam.SelectSubjectList[index].SelectExamSubjects : []
                        return item
                    })
                    this.ExamData = res
                }
            } else {
                if (this.ExamData.IsNewExam || !this.ExamData.RuleList) {
                    let temp = [{
                        MaxSelectCount: 1,
                        SelectExamSubjects: ['文科', '理科'],
                        selected: exam.SubjectType ? (exam.SubjectType === 1 ? ['文科'] : ['理科']) : []
                    }]
                    this.ExamData.IsNewExam = false
                    this.$set(this.ExamData, 'RuleList', temp)
                }
            }
        },
        // 保存
        submitForm(formName) {

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = null
                    if (this.ExamData.IsNewExam) {
                        let flag0 = this.ExamData.RuleList.some(item => item.selected.length !== 0)

                        if (flag0) {

                            let flag1 = this.ExamData.RuleList.some(item => item.selected.length !== item.MaxSelectCount)

                            if (flag1) {

                                Message({ type: 'error', message: '请完善选科！' })
                                return false
                            } else {
                                data = []
                                this.ExamData.RuleList.map(item => {
                                    data.push({
                                        SelectExamNum: item.SelectExamNum,
                                        SelectExamTitle: item.SelectExamTitle,
                                        SelectExamSubjects: item.selected
                                    })
                                })
                            }
                        } else data = []



                        // let flag1 = this.ExamData.RuleList.some(item => item.selected.length !== 0 && item.selected.length !== item.MaxSelectCount)

                        // if (flag1) {

                        //     Message({ type: 'error', message: '请完善选科！' })
                        //     return false
                        // } else { // 已完善
                        //     data = []
                        //     this.ExamData.RuleList.map(item => {
                        //         data.push({
                        //             SelectExamNum: item.SelectExamNum,
                        //             SelectExamTitle: item.SelectExamTitle,
                        //             SelectExamSubjects: item.selected
                        //         })
                        //     })
                        // }
                    } else {

                        if (this.ExamData.RuleList[0].selected.length !== 0) {

                            data = this.ExamData.RuleList[0].selected[0] === '文科' ? 1 : 2
                            // Message({ type: 'error', message: '请完善选科！' })
                            // return false
                        }
                    }

                    let par = {
                        UserId: JSON.parse(getStore('LoginData')).UserId,
                        ExamProvinceId: this.ruleForm.ExamProvinceId,
                        ExamProvinceName: this.ExamProvinceData.find(f => f.ProvinceId === this.ruleForm.ExamProvinceId).ProvinceName,
                        ExamYear: this.ruleForm.ExamYear,
                        SubjectType: this.ExamData.IsNewExam ? null : data,
                        SelectSubjectList: this.ExamData.IsNewExam ? data : [],
                        Score: this.ruleForm.Score
                    }

                    let tempText = this.isAdd?'是否确认提交':'注：高考省份、高考年份、选科保存后不允许修改，请确认无误'

                    this.$confirm(tempText, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {

                        this.submit(par);
                    }).catch(() => {
                    });
                }
            });
        },
        //提交
        submit(par) {

            API.Personal.UpdateUserExam(par).then(res => {
                if (res.Code === 0) {
                    this.isAdd = true;

                    if (this.ExamData.IsNewExam) {
                        if (!par.SelectSubjectList || par.SelectSubjectList.length === 0) {
                            this.subjectDisabled = false
                        } else this.subjectDisabled = true
                    } else {
                        if (!par.SubjectType) {
                            this.subjectDisabled = false
                        } else this.subjectDisabled = true
                    }

                    // 设置成功后修改全局省份
                    let tempItem = this.ExamProvinceData.find(item => item.ProvinceId === this.ruleForm.ExamProvinceId)

                    setStore('OrgProvince', tempItem)

                    if (this.$route.query.source === 'TB') {
                        this.$store.dispatch('user/judgeInfoComplete').then(() => {
                            Message({ type: 'success', message: '保存成功！' });
                            this.$router.push('/')
                        })
                        return
                    }

                    Message({ type: 'success', message: '保存成功！' });
                    let url = decodeURIComponent(location.href).split('?redirect=')[1]
                    setTimeout(() => {

                        if (url) {
                            if (!url.includes('&')) {
                                this.$router.push(url)
                            } else {
                                let temp = url.split('&')

                                let temp2 = temp.splice(1).reduce((prev, curr) => {
                                    return prev + '&' + curr
                                }, '')
                                this.$router.push(temp[0] + '?' + temp2)

                            }
                        }

                    }, 500)
                }
                else {
                    Message({ type: 'error', message: res.Message });
                }
            });
        }
    }
}
</script>
<style lang="less" scoped>
.examinee_box {
    margin: 10px;
    border: 1px solid #e4e4e4;
    padding: 20px 0 0;
}

.examinee_box .el-form .el-form-item .tips {
    color: #757575;
    font-size: 12px;

    i {
        margin-right: 5px;
    }
}
</style>
<style lang="less">
.examinee_box .el-form .el-form-item {
    max-width: 80%;
    min-width: 400px;
}

.examinee_box .el-form .el-form-item .el-button--primary {
    width: 120px;
    background-color: rgba(var(--themecolor), 0.85);
    border-color: rgba(var(--themecolor), 0.85);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}

.examinee_box .el-form .el-form-item .el-button--primary:hover {
    background-color: rgba(var(--themecolor), 1);
    border-color: rgba(var(--themecolor), 1);
}

.examinee_box .el-form .el-form-item .el-input__inner:focus {
    border-color: #ddd;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
</style>